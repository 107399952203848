$(document).ready(function () {
    $eyecatch = $('.hero__images__item');
    let swiper;

    if ($('.pages__index__projects .container').length > 0) {
        swiper = new Swiper('.pages__index__projects .container', {
            // Optional parameters
            slidesPerView: 1,
            spaceBetween: -10,
            loop: false,
            breakpoints: {
                760: {
                    spaceBetween: 15,
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                }
            },
            navigation: {
                nextEl: ".project-button-next",
                prevEl: ".project-button-prev",
            },
        })
    }
});