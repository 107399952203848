(function ($) {

    $(document).ready(function () {
        $swiper_players = $('.sharesato__players__swiper');
        let swiper;

        if ($swiper_players.length > 0) {
            swiper = new Swiper('.sharesato__players__swiper', {
                updateOnWindowResize: true,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.players-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                    320: {
                        slidesPerView: 2,
                        slidesPerColumn: 1,
                        spaceBetween: 10,
                        slidesPerGroup: 2
                    },
                    992: {
                        slidesPerView: 4,
                        slidesPerColumn: 2,
                        slidesPerColumnFill: 'row',
                        spaceBetween: 10,
                        slidesPerGroup: 4
                    },
                }
            });

            $(window).resize(function() {
                swiper.update()
            });
        }

        $sharesato__sticky = $('.sharesato__sticky');
        if ($sharesato__sticky.length > 0) {

            if ($(window).scrollTop() > $swiper_players.offset().top - $swiper_players.outerHeight() - 200) {
                $(".floating-btn").removeClass("show");
            } else {
                $(".floating-btn").addClass("show");
            }

            $sharesato__sticky.on("click", function () {
                $("html,body").animate({scrollTop: $("#players").position().top + 100});
            });

            $(window).on('scroll', function () {
                if ($(window).scrollTop() > $swiper_players.offset().top - $swiper_players.outerHeight() - 200) {
                    if ($(".floating-btn").hasClass("show")) {
                        $(".floating-btn").removeClass('show');
                        $(".floating-btn").fadeOut();
                    }
                } else {
                    if (!$(".floating-btn").hasClass("show")) {
                        $(".floating-btn").addClass('show');
                        $(".floating-btn").fadeIn();
                    }
                }
            });

        }
    });
})(jQuery);