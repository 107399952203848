$(document).ready(function () {
    $eyecatch = $('.hero__images__item');
    let swiper;

    if ($('.pages__index__feature .container').length > 0) {
        swiper = new Swiper('.pages__index__feature .container', {
            slidesPerView: 1,
            spaceBetween: 15,
            breakpoints: {
                760: {
                    slidesPerView: 2,
                    spaceBetween: 40,
                }
            }
        })
    }

    $button = $('.disable-double-click');
    if ($button.length > 0) {
        $button.click(function(){
            $(this).addClass('disabled')
        })
    }
});
