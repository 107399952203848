(function ($) {
    $(document).ready(function () {
        (function () {
            var donationSearch = $("#donation-search");
            if (donationSearch === undefined) {
                return;
            }
            donationSearch.on('click', function (event) {
                event.preventDefault();
                donationSearch.addClass("disabled");
                var zipCode = $("#donation_zip_code").val();
                $.ajax(
                    'https://zip-cloud.appspot.com/api/search',
                    {
                        type: "GET",
                        dataType: "jsonp",
                        data: {
                            zipcode: zipCode.replace("-", "")
                        },
                    })
                    .then(
                        (data) => {
                            donationSearch.removeClass("disabled");
                            if (data.status !== 200) {
                                return;
                            }
                            $("#donation_municipalities").val(
                                data.results[0].address2
                                + data.results[0].address3
                            );
                            $("#donation_prefecture_code").val(data.results[0].prefcode)
                        },
                        (error) => {
                            donationSearch.removeClass("disabled");
                        }
                    );
            });
        })();

        (function () {
            var donationSearch = $("#donation-return-search");
            if (donationSearch === undefined) {
                return;
            }
            donationSearch.on('click', function (event) {
                event.preventDefault();
                donationSearch.addClass("disabled");
                var zipCode = $("#donation_return_zip_code").val();
                $.ajax(
                    'https://zip-cloud.appspot.com/api/search',
                    {
                        type: "GET",
                        dataType: "jsonp",
                        data: {
                            zipcode: zipCode.replace("-", "")
                        },
                    })
                    .then(
                        (data) => {
                            donationSearch.removeClass("disabled");
                            if (data.status !== 200) {
                                return;
                            }
                            $("#donation_return_municipalities").val(
                                data.results[0].address2
                                + data.results[0].address3
                            );
                            $("#donation_return_prefecture_code").val(data.results[0].prefcode)
                        },
                        (error) => {
                            donationSearch.removeClass("disabled");
                        }
                    );
            });
        })();

        (function () {
            var checkBox = $("#donation_is_return_value");

            if (checkBox.length === 0) {
                return;
            }

            if (checkBox.prop('checked')) {
                $('.return-container').hide();
            }

            checkBox.on('change', function () {
                if (checkBox.prop('checked')) {
                    $('.return-container').slideUp();
                } else {
                    $('.return-container').slideDown();
                }
            });


        })();


        (function () {
            var checkBox = $("#donation_one_stop");

            if (checkBox.length === 0) {
                return;
            }

            if (!checkBox.prop('checked')) {
                $('.one-stop-container').hide();
            }

            checkBox.on('change', function () {
                if (checkBox.prop('checked')) {
                    $('.one-stop-container').slideDown();
                } else {
                    $('.one-stop-container').slideUp();
                }
            });


        })();

    });
})(jQuery);