(function ($) {
    $(document).ready(function () {
        let swiper;

        if ($('.hero__container').length > 0) {
            swiper = new Swiper('.hero__container', {
                loop: true,
                slidesPerView: 1,
                spaceBetween: 15,
                autoplay: {
                    delay: 3500,
                },
                breakpoints: {
                    760: {
                        slidesPerView:1,
                        spaceBetween: -25,
                    },
                },
            });

            swiper.on('slideChangeTransitionStart', function (aaa) {
                let selected = swiper.realIndex;
                $('.lines .line').each(function (index, elem) {

                    if (selected === index) {
                        $(elem).addClass('active');
                    }else{
                        $(elem).removeClass('active');
                    }
                });
            });
        }

        if ($('.lines .line').length > 0) {
            $('.lines .line').on('click', function () {
                let num = $(this).data('eyecatch');
                swiper.slideTo(num + 1);
            });
        }

    });
})(jQuery);