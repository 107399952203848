$(document).ready(function () {
    $eyecatch = $('.hero__images__item');
    let swiper;

    if ($('.pages__index__flow .container').length > 0) {
        swiper = new Swiper('.pages__index__flow .container', {
            // Optional parameters
            slidesPerView: 1,
            spaceBetween: 15,
            breakpoints: {
                760: {
                    spaceBetween: 15,
                    slidesPerView: 4,
                }
            }
        })
    }
});